.login-container .row>* {
  margin: 0;
}

.app-name {
  padding-bottom: 10%;
  padding-top: 10%;
  font-family: "G&B-Headline", sans-serif;
  font-size: 2.5rem;
  color: #810055;
}

.login-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.login-container-left {
  /* width: 66%; */
  position: relative;
  height: 100vh;
}

.login-container-right {
  display: flex;
  flex-direction: column;
  /* width: 34%; */
  /* text-align: center; */
  padding: 6rem 4rem;
}

.login-btn {
  background-color: #810055 !important;
  border: 0px !important;
  border-radius: 50rem;
  padding: 0.75rem;
  font-size: 1rem;
  font-family: 'G&B-BodyCopyHighlight', sans-serif;
}

.error-box {
  position: absolute;
  margin: auto;
  max-width: 400px !important;
  max-height: 200px !important;
  color: #E1142E;
  background-color: #F1F1ED;
  left: 0;
  right: 0;
  top: 0;
  bottom: 30%;
  border-radius: 1rem;
  padding: 2% !important;
}

@media only screen and (max-width: 767px) {
  .login-container-left {
    display: none;
  }

  .login-container-right {
    width: 100%;
  }
}

.login-container-left {
  /* margin-top: 3%; */
  /* width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  position: absolute; */
  background: url(./images/LoginImage.png) no-repeat;
  background-position: 0 0 !important;
  background-size: cover;
  height: 100vh;
}

.login-width {
  width: 100%;
}