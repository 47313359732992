@font-face {
  font-family: "G&B-BodyCopy";
  src: url("css/G&B-BodyCopy.woff2") format("woff2"),
    url("css/G&B-BodyCopy.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "G&B-Headline";
  src: url("css/G&B-Headline.woff2") format("woff2"),
    url("css/G&B-Headline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "G&B-BodyCopyHighlight";
  src: url("css/G&B-BodyCopyHighlight.woff2") format("woff2"),
    url("css/G&B-BodyCopyHighlight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@media print {
  @page landscape {
    size: landscape;
  }

  @page portrait {
    size: portrait;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: "G&B-BodyCopy", sans-serif;
}

body:not(.modal-open) {
  padding-right: 0px !important;
}

.header .navbar-text {
  color: #810055 !important;
  font-size: 24px;
  /* font-weight: 600; */
  margin-left: 10px;
  font-family: "G&B-Headline", sans-serif;
}

.header {
  background: #FFFFFF;
  height: 65px;
  color: #333333;
  /* -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); */
  /* -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); */
  border: 1px solid #F1F1ED;

}

.header .container {
  max-width: 100%;
}

.wrapper {
  width: 100%;
  align-items: stretch;
}

.Main-Dashboard {
  background: rgb(255, 255, 255);
  padding: 10px;
  min-height: 80vh;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto;
}

.table-Div p {
  text-align: center;
  font-size: 15px;
  font-family: "G&B-Headline", sans-serif;
  margin-bottom: 10px !important;
}


.layout-content .row {
  margin: 0 !important;
}

/* .layout-content .row>* {
  padding: 0 !important;
} */

/* .layout,
.layout * {
  box-sizing: border-box;
} */

.layout {
  display: flex;
  padding: 0;
  /* flex: auto; */
  /* width: 100vw; */
  flex-direction: column;
  min-height: 0;
  background: #FFFFFF;
}

.layout-content {
  flex: auto;
  min-height: 0;
  padding: 0px 20px;
}

.main-layout {
  display: flex;
  /* min-height: calc(100vh - 65px); */
  background: #FFFFFF;
  padding: 0;
  flex-direction: row;
}

.bread-crumb {
  margin: 12px 0px;
  padding: 0;
  /* font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum"; */
  color: #333333;
  font-size: 18px;
  /* font-weight: bold; */
  font-family: "G&B-Headline", sans-serif;
}

/* .breadcrumb>.active {
  margin: 12px 0px;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  color: #333333 !important;
  font-size: 20px;
  font-weight: bold;
} */

footer {
  text-align: center;
  margin-top: 10px;
  /* padding: 20px 50px; */
  /* color: rgba(0, 0, 0, 0.85); */
  font-size: 15px;
}

.table-Div {
  background: rgb(255, 255, 255);
  position: relative;
  /* padding: 15px 0; */
  /* display: flex; */
  display: block;
  flex-direction: column;
  /* border: 1px solid #F1F1ED; */
  /* box-shadow: 0 1px 2px rgb(0 0 0 / 8%); */
  /* border-radius: 10px; */
  overflow-y: auto;
  /* height: 32em; */
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 4px;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/* .dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
} */

tr.header-class th {
  /* width: 4%;*/
  font-size: 15px;
  vertical-align: middle;
  height: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.table-Div .row>* {
  /* padding-right: 0px !important;
  padding-left: 0px !important; */
  padding-bottom: 10px !important;
}

.modal-body .row>* {
  padding-right: 0px;
  padding-left: 0px;
}

.header-class {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 500;
  /* text-align: center; */
}

tr.row-class td {
  font-size: 15px;
  vertical-align: middle;
  height: 40px;
  padding-top: 2px;
  padding-bottom: 2px;
  word-break: break-all;
  /* text-overflow: ellipsis;
  overflow: hidden;
  max-width: 5em; */
}

.filter-label {
  width: 90px;
  margin: 5px;
}

hr {
  margin: 10px 0 !important;
}

/* FORMS  Start*/

.control {
  overflow: hidden;
  /* max-width: 100%; */
  position: relative;
  padding-bottom: 18px;
}

.control label,
.controlDesign label {
  text-align: left;
  font-family: "G&B-Headline", sans-serif;
  margin-bottom: 0;
  /* margin-left: 5px; */
  font-size: 14px;
  color: #707070;
}

.form-label {
  word-wrap: "break-word";
}

.filter-control .form-label {
  text-align: left;
  font-family: "G&B-Headline", sans-serif;
  margin: 0px 8px;
  font-size: 15px;
  color: #707070;
}

.control input,
.control select,
.controlDesign input,
.controlDesign select {
  border: 1px solid #F1F1ED;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  margin: 5px 5px 5px 0px;
  width: 95%;
  padding: 4px 2px;
}

.filter-control input {
  padding: 8px;
  margin-left: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  /* width: 95%; */
}

.filter-control input:focus,
.form-select:focus,
.control input:focus,
.controlDesign input:focus,
.controlDesign select:focus {
  box-shadow: none;
  border-color: #333333;
  color: #333333;
}

/* .form-switch .form-check-input {
    margin-left: -1.5em;
} */

/* .form-switch .form-check-input {
  margin-left: -1.8em;
  width: 40px;
  height: 22px;
} */

.form-switch .form-check-input {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  border: 1px solid #810055;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(129,0,85)'/></svg>");
}

.form-check-input:checked {
  background-color: #810055;
  border-color: #810055;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255)'/></svg>");
}

.control small {
  color: #E1142E;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 10px;
  font-size: inherit;
  font-weight: 500;
  /* visibility: hidden; */
}

.error small,
.error .FaExclamationCircle,
.success .FaCheckCircle {
  visibility: visible;
}

.error {
  border-color: #E1142E !important;
}

.success {
  border-color: #33C037 !important;
}

.FaExclamationCircle,
.FaCheckCircle {
  /* visibility: hidden; */
  position: absolute;
  top: 36px;
  right: 45px;
}

.img,
svg {
  vertical-align: baseline;
}

/* FORMS  End*/

/* #SideBarBody  */

.navigation-list {
  list-style-type: none;
  padding: 0px 4px;
  margin-top: 45px;
  height: 410px;
  overflow-y: auto;
  scrollbar-width: none;
}

.navigation-list-item {
  padding: 5px;
  margin: 5px 0px;
  border-radius: 8px;
}

.navigation-link {
  color: #707070;
  letter-spacing: 0.5px;
  font-weight: 400;
  text-decoration: none;
  font-size: 15px;
  font-family: "G&B-BodyCopy";
  padding: 0;
  position: relative;
}

.navigation-list-item:hover .navigation-link {
  color: #810055;
  font-weight: bold;
}

.navigation-list-item:active .navigation-link {
  color: #810055;
  font-weight: bold;
}

/* .navigation-link:active .navlink-text{
  color: #810055;
} */

.navigation-list-item:hover {
  background: #F9F2F6;
  box-shadow: 0 0 0.4em rgb(255, 255, 255, 0.1);
  cursor: pointer;
}

.navigation-list-item.active .navigation-list-item {
  background: #F9F2F6;
  color: #810055;
  box-shadow: 0 0 0.4em rgb(255, 255, 255, 0.1);
}

.navlink-text.hide {
  display: none;
  transition: all 0.5s ease;
}

.navigation-link-subMenu {
  /* padding-left: 7px; */
  transition: all 0.5s ease;
}

.navigation-link-subMenu .navigation-list-item {
  margin: 0;
  padding: 7px;
}

#SideBar {
  position: relative;
  background: #FFFFFF;
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start !important;
  transition: all 0.5s ease;
  border: 1px solid #F1F1ED;
  height: 100vh;
  /* box-shadow: 0 10px 30px -12px rgb(0 0 0 / 42%),
    0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%); */
}

.sider-children {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  color: #810055;
}

.open-sidebar {
  width: 200px !important;
  /* transition: width 0.5s ease; */
}

.closed-sidebar {
  width: 48px !important;
}

.open-sidebar .navigation-link-subMenu {
  padding-left: 7px;
}

.toggle-icon-wrapper {
  padding-left: 0.125rem;
  margin-right: 1.25rem;
  display: none;
}

.headToggler {
  height: 40px;
  width: 40px;
  /* border-radius: 50%; */
}

.navToggler {
  position: absolute;
  top: 35px;
  /* left: 65px; */
  right: -10px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  /* background-color: #695cfe; */
  background-color: #810055;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.closed-sidebar .navToggler {
  transform: translateY(-50%) rotate(0deg);
}

.tooltip_menu {
  position: absolute;
  top: -1rem;
  left: 2rem;
  background-color: white;
  color: #333333;
  border-radius: 0.5rem;
  padding: 0.2rem 0.2rem;
  font-size: 0.8rem;
  transition: 0s;
  white-space: nowrap;
  visibility: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 4;
}

.avtar {
  margin-left: 5px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  cursor: pointer;
  border: 1.5px solid #810055;
  border-radius: 50%;
}

.navbar .dropdown-menu.dropdown-menu-end:after {
  right: 12px;
  left: auto;
}

.dropdown .dropdown-menu {
  border-radius: 10px;
  box-shadow: 1px 2px 3px rgb(0 0 0 / 13%);
  transition: display 300ms ease 0s;
}

.navbar .dropdown-menu:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 12px;
  height: 12px;
  background: inherit;
  top: -0.375rem;
  left: 1.8rem;
  transform: rotate(45deg);
}

.action-btn {
  cursor: pointer;
}

.closed-sidebar .navigation-link:hover .tooltip_menu,
.action-btn:hover~.tooltip_menu {
  opacity: 1 !important;
  visibility: visible !important;
  top: -50%;
  transform: translateY(-20%);
  transition: all 0.4s ease;
}

.control.error input {
  animation: shake 300ms;
}

.welcome-text {
  display: flex;
  align-items: center;
  color: #333333;
  /* float: right; */
}

@keyframes shake {
  25% {
    transform: translateX(4px);
  }

  50% {
    transform: translateX(-4px);
  }

  75% {
    transform: translateX(4px);
  }
}

.dashboard {
  /* height: 15.6rem; */
  margin-bottom: 0.7rem;
  border: 1.5px solid #F1F1ED;
  padding: 0.75rem;
}

.dashboard-closed {
  /* height: 32rem; */
  border: 1.5px solid #F1F1ED;
  padding: 0.75rem;
}

/* .dashboard .react-bootstrap-table {
  width: 100%;
  overflow-x: auto;
}

.dashboard .react-bootstrap-table th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
} */

.dashboard tr.row-class td,
.dashboard-closed tr.row-class td {
  height: 25px !important;
}

.dashboard .table-bordered {
  border-collapse: separate;
  border-spacing: 0;
}

.main-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 65px);
}

.main-container.open {
  max-width: calc(100% - 230px);
}

.main-container.closed {
  max-width: calc(100% - 48px);
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
  text-align: left;
  flex: none;
  width: 20%;
}

#SideBar.open-sidebar .navlink-text {
  visibility: visible;
  opacity: 1;
}

#SideBar.closed-sidebar .navlink-text {
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
}

.modal-backdrop {
  z-index: 1055 !important;
}

.dashbord-col {
  padding-right: 15px;
}

/* Media Queries */

@media (max-width: 768px) {
  .modal-dialog {
    /* width: 70%; */
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .main-layout {
    flex-direction: column;
  }

  .main-container.open,
  .main-container.closed {
    max-width: 100%;
  }

  .layout-content {
    padding: 0px 15px;
  }

  #SideBar.open-sidebar {
    width: 100% !important;
    position: fixed !important;
    z-index: 10000;
    visibility: visible;
    opacity: 1;
  }

  #SideBar.closed-sidebar {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 100% !important;
  }

  .toggle-icon-wrapper {
    display: block !important;
  }

  .navToggler {
    display: none !important;
  }

  .sider-children {
    min-width: 260px !important;
  }

  .navigation-list {
    margin-top: 5px;
    padding: 0px 15px;
    width: 100%;
  }

  .navigation-link-subMenu {
    padding-left: 25px;
  }

  .navbar-text,
  .welcome-text .account-user-name {
    display: none;
  }

  .dashboard,
  .dashboard-closed {
    height: 25rem !important;
  }
}

.custom-footer {
  justify-content: center !important;
  display: block !important;
}

.custom-col {
  margin-top: 1em;
  margin-left: 1em;
}

.scroll-auto {
  height: 200px;
  width: 1100px;
  overflow-x: auto;
  overflow-y: auto;
}

.form-control {
  max-height: 30px !important;
  padding: 0rem 0.375rem;
  color: #333333;
}

.form-control:disabled,
.form-select:disabled {
  background-color: #F1F1ED;
}

.filter-control {
  max-height: 72px !important;
  padding: 0.25rem 0.375rem;
}

.form-select {
  max-height: 30px !important;
  padding: 0.001rem 0.375rem;
  appearance: auto !important;
  background-image: none;
  color: #333333;
}

.displayLink {
  color: #810055;
  cursor: pointer;
}

.search-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.css-tlfecz-indicatorContainer {
  color: #333333 !important;
}

.printButton {
  height: 20px;
  width: 20px;
  margin-left: 82%;
}

.lazy-spinner {
  /* width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  position: fixed;
  z-index: 5060 !important;
  inset: 0;
  background: rgba(59, 63, 74, 0.85);
}

.spinner-color {
  /* color: #d02871;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%; */
  position: absolute;
  width: 2rem !important;
  height: 2rem !important;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  z-index: 5000 !important;
}

.height-20 {
  height: 20vh !important;
}

@media screen and (max-width: 768px) {
  .search-btn {
    min-height: -webkit-fill-available;
    min-width: -webkit-fill-available;
    margin-right: 1em;
  }

  .margin-12 {
    margin: 12px !important;
  }

  .table-col-sub .table-label {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .search-btn {
    width: 90%;
    /* margin-right: 1em; */
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .margin-12 {
    margin: 12px !important;
  }
}

.required:after {
  color: #E1142E;
  content: "*";
  display: inline;
}

.table>thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
}

tfoot tr:last-child {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: white;
}

.btn {
  font-family: "G&B-Headline", sans-serif !important;
  font-size: 14px !important;
  font-weight: 100 !important;
}

.btn-close:focus {
  box-shadow: none;
}

.header-btn-row {
  padding-left: 12px !important;
  padding-bottom: unset !important;
  margin-bottom: unset !important;
}

.dashboard-download-lbl-btn {
  text-align: end;
  width: 37%;
}

.dashboard-download-lbl-btn1 {
  text-align: start;
  width: 60%;
}

.btn-download-dash-charts {
  float: right;
  justify-content: center;
  font-size: 18px;
}

.seperateLine {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  border-top: 1px solid #EBEBEC;
}

.add-container {
  margin: 1rem 5px;
}

.headerTabKey {
  --bs-nav-link-padding-x: none;
  --bs-nav-link-padding-y: none;
}

.tabCssheader {
  margin: 0.3rem 0;
  width: 100%;
  display: inline-flex;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  border-top: none;
  border-left: none;
  border-right: 1px solid #EBEBEC;
  border-radius: 0px;
  color: #333333;
  text-align: left;
  font-size: 14px;
}

.nav-item:last-child {
  flex: 1;
}

.addRowDesignPlan {
  margin: 0.6rem 0rem;
}

/* .nav-tabs .nav-item.show .nav-link, */
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #810055;
  color: #810055;
  text-align: left;
}

.nav-link.active span {
  font-family: "G&B-Headline", sans-serif !important;
}

.approveBtn {
  width: 109px;
  height: 40px;
  margin-right: 10px;
}

.add-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-outline-success {
  --bs-btn-color: #707070 !important;
  --bs-btn-border-width: 1.5px;
  --bs-btn-border-color: #F1F1ED !important;
  --bs-btn-hover-bg: #FFFFFF !important;
  --bs-btn-hover-border-color: #F1F1ED !important;
  --bs-btn-hover-color: #707070 !important;
  --bs-btn-border-radius: var(--bs-border-radius-pill) !important;
  --bs-btn-active-bg: #FFFFFF;
  --bs-btn-active-color: #707070;
  --bs-btn-active-border-color: #F1F1ED;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-width: 0;
  --bs-btn-disabled-bg: rgb(129, 0, 133, 0.4);
  --bs-btn-disabled-border-color: rgb(129, 0, 133, 0.4);
}

.btn-outline-success:disabled {
  --bs-btn-border-width: 0;
  --bs-btn-disabled-bg: rgb(129, 0, 133, 0.4);
  --bs-btn-disabled-border-color: rgb(129, 0, 133, 0.4);
}

/* .btn-outline-primary {
  --bs-btn-color: #810055 !important;
  --bs-btn-bg: #FFFFFF;
  --bs-btn-border-color: #810055 !important;
  --bs-btn-border-radius: var(--bs-border-radius-pill) !important;
  --bs-btn-hover-bg: #810055 !important;
  --bs-btn-hover-border-color: #810055 !important;
  --bs-btn-hover-color: #FFFFFF !important;
  --bs-btn-active-bg: #810055;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-border-color: #810055;
} */

.btn-primary {
  --bs-btn-bg: #810055;
  --bs-btn-border-color: #810055;
  --bs-btn-hover-bg: #810055;
  --bs-btn-hover-border-color: #810055;
  --bs-btn-active-bg: #810055;
  --bs-btn-active-border-color: #810055;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgb(129, 0, 133, 0.4);
  --bs-btn-disabled-border-color: rgb(129, 0, 133, 0.4);
  --bs-btn-border-radius: var(--bs-border-radius-pill) !important;
  --bs-btn-focus-shadow-rgb: none;
}

.backdropModal {
  position: absolute;
  top: -600px;
  left: -20px;
  width: 300vw;
  height: 700vh;
  background: rgba(0, 0, 0, 0.55);
}

.rightAligned {
  display: inline-flex;
  justify-content: end
}

.modalTitle {
  font-family: "G&B-Headline", sans-serif;
  font-size: 16px !important;
}

/* .modal-body {
  margin: 10px !important;
} */

.colWidth {
  width: 13% !important;
}

.colWidth1 {
  width: 10% !important;
}

.colWidth2 {
  width: 4% !important;
}

.colWidth3 {
  width: 17% !important;
}

.colWidth4 {
  width: 15% !important;
}

.colWidth5 {
  width: 11% !important;
}

.colWidth6 {
  width: 19.67% !important;
}

.colWidth7 {
  width: 20% !important;
}

.colWidth7 .btn {
  padding: 7px 2px !important;
}

.errorDesign .errorDesign small {
  position: relative;
  visibility: visible;
}

.controlDesign small {
  color: #E1142E;
  position: relative;
  bottom: 0;
  left: 0;
  font-size: small;
  font-weight: 500;
}

.designDeleteBtn {
  margin-top: 2rem;
  max-width: 4% !important;
}

.workflowImage {
  height: 25px;
  width: 25px;
  margin: 0 5px;
  /* filter: invert(8%) sepia(99%) saturate(7447%) hue-rotate(248deg) brightness(97%) contrast(145%); */
}

.swal2-styled.swal2-deny {
  border: 1px solid #810055;
  background-color: #fff;
  color: #810055;
  height: 40px;
  width: 109px;
  padding: 0px;
  font-size: 14px;
  margin-right: 4%;
  border-radius: 50rem;
  font-family: "G&B-Headline", sans-serif;
}

.swal2-styled:hover {
  background-image: none !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
  background-color: #810055;
  height: 40px;
  width: 109px;
  padding: 0px;
  font-size: 14px;
  border-radius: 50rem;
  font-family: "G&B-Headline", sans-serif;
}

.swal-confirmation {
  width: 523px !important;
  border-radius: 0px;
}

.swal-success {
  width: 24em !important;
  font-size: 15px;
  font-family: "G&B-BodyCopy", sans-serif;
  color: #333333;
}

.swal2-title {
  text-align: left !important;
  margin-top: 5px !important;
  font-size: 16px;
  font-family: "G&B-Headline";
  color: #333333;
  font-weight: 100 !important;
}

.swal-confirmation .swal2-close {
  display: block !important;
  color: #707070;
  font-size: 30px;
}

.swal2-close:hover {
  color: #707070;
}

.swal-confirmation .swal2-actions {
  width: 100% !important;
  /* justify-content: space-around !important; */
  margin: 0px;
  padding-bottom: 30px !important;
}

.swal2-html-container {
  font-family: "G&B-BodyCopy" !important;
  font-size: 14px !important;
  color: #333333 !important;
}

.swal-confirmation .swal2-html-container {
  margin: 50px !important;
}


.border-col-cell {
  border-bottom: 1px solid #D8D8D8;
  border-right: 1px solid #D8D8D8;
  /* display: flex;
  text-align: left;
  align-items: center; */
  /* word-break: break-word !important; */
}

/* .border-col-cell label {
  margin-right: 5px !important;
  margin-left: 5px !important;
} */

.col-cell-border {
  border-bottom: 1px solid #D8D8D8;
}

.row-wise-list {
  border: 1px solid #D8D8D8;
  margin-bottom: 18px;
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
}

.designPlanRow,
.designIssueRow {
  border: 1px solid #D8D8D8;
}

.designIssueTableRow {
  margin-left: 0 !important;
}


.designPlanRow .row>* {
  padding-left: 0.5rem !important;
}

.designIssueRow .row>* {
  padding-left: 0.5rem !important;
  font-size: 14px !important;
}

.designrelease-list,
.designrelease-projuser-matrix {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.submit-btn {
  text-align: end;
}

.align-center {
  align-content: center;
}

.designrelease-list input,
.designrelease-list select {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.designrelease-list-approve {
  text-align: center;
}

.lbl-extrawidth {
  width: 110% !important;
}

.center-align {
  text-align: center;
}

@media (max-width: 768px) {

  .colWidth,
  .colWidth1,
  .colWidth2,
  .colWidth3,
  .colWidth4,
  .colWidth5,
  .colWidth6,
  .colWidth7 {
    width: 100% !important;
  }

}

.dropZoneContainer {
  background: #FFFFFF;
  border: 2px dashed #F1F1ED;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  max-height: 30px;
  /* margin-left: 5px !important;
  margin-bottom: 5px !important; */
  font-size: 12px;
  /* position: relative; */
  margin: 5px 5px 5px 0px;
  padding: 4px 2px;
  overflow: inherit;
}

.dropText {
  color: #810055;
}

.file-cross {
  text-align: center;
  margin: 2px;
  align-content: center;
}

.design-file-lable {
  background: #FFFFFF !important;
  display: flex;
  justify-content: center;

}

.logoHeader {
  padding: 0px 6rem;
  border: 1px solid #F1F1ED;
  position: absolute;
  z-index: 9999;
  background-color: white;
  height: 56px;
  margin: 0 !important;
  width: 100%;
}

.headerLink {
  font-size: 0.75rem;
  color: #810055;
}

.sidebarLogo {
  display: flex;
  justify-content: center;
}

.open-sidebar .sidebar-logo {
  width: 70px;
  /* margin-left: 30%;
  margin-top: 65%; */
  position: fixed;
  bottom: 10px;
}

.closed-sidebar .sidebar-logo {
  width: 40px;
  /* padding: 0.5rem 0.65rem;
  margin-top: 10.5rem; */
  position: fixed;
  bottom: 10px;
}

::-webkit-scrollbar-track {
  background-color: white;
  /* -webkit-box-shadow: inset 0 0 6px #333(0,0,0,0.3) !important;  */
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  /* border-radius: 10px; */
  background: #707070;
  /* -webkit-box-shadow: inset 0 0 6px #333(0,0,0,0.5) !important;  */
  /* box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #707070;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: white;
  /* -webkit-box-shadow: inset 0 0 6px #333(0,0,0,0.3) !important;  */
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  /* box-shadow: inset 0 0 5px grey;
  border-radius: 5px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  /* border-radius: 10px; */
  background: #707070;
  /* border-radius: 5px; */
}

.text-danger {
  color: #E1142E !important;
  background-color: #FFFFFF !important;
}

.css-9jq23d {
  color: #333333 !important;
}

.css-1p3m7a8-multiValue {
  background-color: #F1F1ED !important;
}

/*selected value cross*/
.css-v7duua:hover {
  background-color: #F1F1ED !important;
  color: #333333 !important;
}

.css-1bfbk92-control:hover,
.css-1bfbk92-control:active {
  border: 1px solid #333333 !important;
}

.modal-title {
  font-family: "G&B-Headline", sans-serif !important;
  color: #333333;
  font-size: 18px;
}

.Toastify__toast {
  font-family: "G&B-BodyCopy", sans-serif !important;
  background: #FFFFFF;
  color: #333333;
  --toastify-color-success: #33C037;
  --toastify-color-error: #E1142E;
}

.issue-email {
  color: #333333;
}